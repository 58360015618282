import { SwipeableDrawer } from "@mui/material";
import Puller from "../Puller/Puller";
import styles from "./BottomDwawer.module.css";

function BottomDrawer({children, ...props}) {
    return (
    <SwipeableDrawer
        anchor="bottom"
        PaperProps={{
          sx: {
            borderTopLeftRadius: 26,
            borderTopRightRadius: 26,
            backgroundColor: "var(--tg-theme-secondary-bg-color)",
            border: "1px 0px 0px 0px",
            padding: "0px 0px 32px 0px",
          },
        }}
        {...props}
      >
          <Puller />
          <div className={styles.drawerContent}>{children}</div>
        </SwipeableDrawer>
)};

  export default BottomDrawer;