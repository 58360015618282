import { styled } from "@mui/material/styles";

const Puller = styled('div')(() => ({
    width: 40,
    height: 6,
    backgroundColor: "var(--tg-theme-hint-color)",
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
  }));

  export default Puller;