import React, { useState } from 'react';
import {
  Button,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { number, object } from 'yup';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './EditQuantityForm.module.css';

const requiredError = 'This field is required!';
const quantityError = 'The quantity must be positive or zero!';

function EditQuantityForm({ initialValues, onSave, onCancel }) {
  const [outOfStock, setOutOfStock] = useState(initialValues.quantity === 0);

  const formik = useFormik({
    initialValues: initialValues || { quantity: 0 },
    validationSchema: object().shape({
      quantity: number().required(requiredError).min(0, quantityError),
    }),
    onSubmit: async (formValues) => {
      const { quantity } = formValues;
      const data = { quantity };
      await onSave(data);
      setOutOfStock(!Number(quantity));
    },
  });

  const {
    values, handleSubmit, handleChange, handleBlur, resetForm, setFieldValue, isValid, dirty, errors, touched,
  } = formik;

  const resetFormValues = () => {
    resetForm();
    setOutOfStock(false);
  };

  const handleOutOfStockChange = (event) => {
    setOutOfStock(event.target.checked);
    if (event.target.checked) {
      setFieldValue('quantity', 0);
    }
    else {
      setFieldValue('quantity', initialValues.quantity);
    }
  };

  const handleCancel = () => {
    resetFormValues();
    onCancel();
  };

  return (
    <FormControl
      onSubmit={handleSubmit}
      size="small"
      component="form"
      className={styles.formContainer}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ paddingBottom: '10px', color: 'var(--tg-theme-section-header-text-color)' }}
      >
        Edit quantity
      </Typography>
      <div className={styles.sectionContainer}>
        <TextField
          id="quantity"
          label="Quantity"
          variant="outlined"
          size="small"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.quantity}
          error={touched.quantity && Boolean(errors.quantity)}
          helperText={touched.quantity && errors.quantity}
          disabled={outOfStock}
          sx={{
            flex: 1,
            margin: ' 0 10px 0 20px!important',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'var(--tg-theme-button-color)',
              },
              '&:hover fieldset': {
                borderColor: 'var(--tg-theme-button-color)',
              },
              '& fieldset': {
                borderColor: 'var(--tg-theme-hint-color)',
              },
              '&.Mui-disabled fieldset': {
                borderColor: 'var(--tg-theme-subtitle-text-color)',
              },
            },
            '& .Mui-disabled': {
              color: 'var(--tg-theme-subtitle-text-color) !important',
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'var(--tg-theme-subtitle-text-color)',
            },
          }}
          InputProps={{ style: { color: 'var(--tg-theme-text-color)', borderRadius: '16px' } }}
          InputLabelProps={{ style: { color: 'var(--tg-theme-subtitle-text-color)' } }}
        />
        <FormControlLabel
          className={styles.checkboxLabel}
          control={
            <Checkbox
              checked={outOfStock}
              onChange={handleOutOfStockChange}
              color="primary"
              className={styles.checkbox}
              sx={{
                color: 'var(--tg-theme-button-text-color)',
                '&.Mui-checked': {
                  color: 'var(--tg-theme-button-color)',
                },
              }}
        />
        }
        label="Out of stock"
      />
      </div>
      <div className={styles.buttonsContainer}>
      <Button
          type="button"
          className={classNames(styles.formButton, styles.cancelButton)}
          variant="outlined"
          disableElevation
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={classNames(styles.formButton, styles.saveButton)}
          variant="contained"
          disableElevation
          disabled={!(isValid && dirty)}
        >
          Save
        </Button>
      </div>
    </FormControl>
  );
}

EditQuantityForm.propTypes = {
  initialValues: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditQuantityForm.defaultProps = {
  initialValues: null,
  onSave: () => {},
  onCancel: () => {},
};

export default EditQuantityForm;