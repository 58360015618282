import React, { useEffect, useCallback } from "react";
import {
  Typography,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/DoneOutlineRounded";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProducts,
  selectAllProducts,
  getProductsStatus,
} from "../../redux/productsSlice";
import {
  getDeleteProductStatus,
  resetDeleteProductStatus,
} from "../../redux/productSlice";
import {
  getPostProductStatus,
  resetPostProductStatus,
} from "../../redux/channelSlice";
import ProductCard from "./ProductCard";
import Skeleton from "./ProductListSkeleton";
import styles from "./ProductList.module.css";
import useQueryParams from "../../hooks/useQueryParams";
import { emptyPlaceholderImg } from "../../api/config";
import BottomDrawer from "../BottomDrawer/BottomDrawer";

function ProductList() {
  const products = useSelector(selectAllProducts);
  const productsStatus = useSelector(getProductsStatus);
  const postProductStatus = useSelector(getPostProductStatus);
  const deleteProductStatus = useSelector(getDeleteProductStatus);
  const dispatch = useDispatch();
  const { shopId } = useQueryParams();

  useEffect(() => {
    if (productsStatus === "idle") {
      dispatch(fetchProducts(shopId));
    }
  }, [dispatch, productsStatus, shopId]);

  const filteredProducts = products;

  const handlePostSuccessScreenClose = useCallback(async () => {
    dispatch(resetPostProductStatus());
  }, [dispatch]);

  const handleDeleteSuccessScreenClose = useCallback(async () => {
    dispatch(resetDeleteProductStatus());
  }, [dispatch]);

  if (productsStatus === "loading") {
    return <Skeleton />;
  }

  if (!products || !products.length) {
    return (
      <div className={styles.productListContainer}>
        <div className={styles.emptyPlaceholder}>
          <img
            src={emptyPlaceholderImg}
            alt=""
            className={styles.emptyPlaceholderImg}
          />
          <Typography variant="body1" className={styles.emptyPlaceholderTitle}>
            No products found
          </Typography>
          <Typography
            variant="body2"
            className={styles.emptyPlaceholderSubtitle}
          >
            Use the bot to add items to your list and see them here.
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.productListContainer}>
      <div className={styles.productList}>
        {filteredProducts.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <BottomDrawer
        open={postProductStatus === "succeeded"}
        onClose={handlePostSuccessScreenClose}
      >
        <div className={styles.successWrapper}>
          <DoneIcon className={styles.iconSuccess} style={{ fontSize: 40 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: "var(--tg-theme-section-header-text-color)" }}
          >
            Product posted successfully
          </Typography>
        </div>
      </BottomDrawer>
      <BottomDrawer
        open={deleteProductStatus === "succeeded"}
        onClose={handleDeleteSuccessScreenClose}
      >
        <div className={styles.successWrapper}>
          <DoneIcon className={styles.iconSuccess} style={{ fontSize: 40 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: "var(--tg-theme-section-header-text-color)" }}
          >
            Product deleted successfully
          </Typography>
        </div>
      </BottomDrawer>
    </div>
  );
}

export default ProductList;
